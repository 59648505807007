@import 'antd/dist/antd.css';

.app {
  background-color: #282c34;
  width: auto;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: hidden;

  a {
    color: hotpink;
    font-weight: 600;
  }
}

.app-layout {
  min-height: 100vh !important;
  width: 90vw;
  max-width: 1280px;
  margin: 0 16px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;

  .app-layout-top {
    width: 100vw;
    flex: 1;
    display: flex;
    flex-flow: column;

    .ant-layout-header {
      display: flex;
      justify-content: center;
      width: 100vw;
      background: transparent !important;
      padding: 0 !important;
      background: #ffffff !important;

      .header-wrapper {
        max-width: 1280px;
        width: 100%;
      }

      .ant-menu-root {
        justify-content: space-between;
        max-width: 1280px;
      }

      .ant-menu > li {
        text-align: center;
      }
    }

    .content-wrapper {
      display: flex;
      flex-flow: row;
      width: 100vw;
      justify-content: center;
      flex: 1;
    }

    .ant-layout-content {
      max-width: 1280px;
      margin: 0 20px;
      flex: 1;
      display: flex;
      flex-flow: row;
      justify-content: center;
    }
    .ant-layout-content-child {
      margin: 0 !important;
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }

  .app-layout-bottom {
    width: 100vw;
    display: flex;
    justify-content: center;
    background: #ffffff;

    .ant-layout-footer {
      max-width: 1280px;
      width: 100%;
      background: #ffffff !important;
      padding-right: 20px;
      padding-left: 20px;

      .footer-wrapper {
        display: flex;
        justify-content: space-between;

        p:hover {
          cursor: pointer;
        }

        .jwbr-logo-container {
          height: 55px;
          display: inline-block;

          img {
            height: 100%;
          }
        }
      }
    }
  }
}

.ant-layout {
  background: transparent !important;
}

.loader-container {
  align-self: center;
}

.slick-dots li button {
  width: 20px !important;
  height: 7px !important;
  background: black !important;
}

.slick-dots li.slick-active button {
  width: 28px !important;
  height: 7px !important;
  background: black !important;
}

.no-match {
  .ant-result-title,
  .ant-result-subtitle {
    color: #ffffff;
  }
  .ant-btn {
    background: transparent;
    color: white;
    border: 1px solid white;
  }
}
